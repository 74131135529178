<script setup lang="ts">

const { locale } = useI18n();
const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
});


useHead({
  htmlAttrs: {
    lang: () => locale.value
  },
  link: () => [...head.value.link || []],
  meta: () => [...(head.value.meta || [])]
})

</script>

<template>
    <div class="flex flex-col h-full">
        <Navbar />
        <main class="flex-1 grow">
            <NuxtPage />
        </main>
        <footer class="bg-slate-300 p-5 text-center mt-4">
            Made with 💚 by Esteban Mayoral
        </footer>
    </div>
</template>
