import { defineStore } from "pinia";

export const useTheme = defineStore('theme', () => {
    const colorMode = useColorMode();
    const cookie = useCookie('theme');
    const theme = computed(() => colorMode.value)

    if(cookie.value && colorMode.value !== cookie.value) {
        colorMode.value = cookie.value;
    }

    function setTheme(value: 'light' | 'dark') {
        cookie.value = value;
        colorMode.value = value;
    }

    return { theme, setTheme }

})