<style scoped>
.navbar-transition {
    view-transition-name: navbar-transition-identifier;
}

</style>

<script setup lang="ts">
import { Input, Button, Modal, Sidebar } from 'flowbite-vue'
import { useTheme } from '@/store/useTheme';
import { storeToRefs } from 'pinia';
import { PostSmall } from 'api/models';
import { useTags } from '@/api';

const themeStore = useTheme();
const switchLocalePath = useSwitchLocalePath();
const localizePath = useLocalePath();
const localePath = useLocalePath();
const { locale, locales, t } = useI18n();

const showModal = ref(false);
const searchText = ref<string>();
const searchTimer = ref();
const showSideMenu = ref(false);

const { theme } = storeToRefs(themeStore);
const { pending, data, error } = await useFetch<PostSmall[]>('/api/search', { query: { q: searchText, locale } });

function switchTheme() {
    const currentColor = theme.value || 'light';
    themeStore.setTheme(currentColor === 'light' ? 'dark' : 'light');
}

function closeModal() {
    showModal.value = false;
    searchText.value = undefined;
}

function openModal() {
    showModal.value = true;
}

const tagsResponse = await useTags(locale.value);

function searchInputChanged(event: InputEvent) {
    if (searchTimer.value) {
        clearInterval(searchTimer.value)
    }

    searchTimer.value = setTimeout(() => {
        const target = event.target as HTMLInputElement;
        searchText.value = target.value;
    }, 200);
}

function hightlightKeywords(resultText: string) {
    const wordsIndexes = new Map<string, number[]>();
    const inputTextSplit = resultText.split(' ');
    inputTextSplit.forEach((e, index) => {
        const text = e.toLocaleLowerCase();
        if(!wordsIndexes.has(text)) {
            wordsIndexes.set(text, []);
        }

        const indexes = wordsIndexes.get(text)!;
        indexes.push(index);
    });

    const searchTextValue = searchText.value || '';
    const uniqueSearchTerms = new Set(searchTextValue.split(' '));

    uniqueSearchTerms.forEach((t) => {
        const searchTextPlain = t.toLowerCase();
        if(wordsIndexes.has(searchTextPlain)) {
            const indexes = wordsIndexes.get(searchTextPlain)!;
            for (const index of indexes) {
                inputTextSplit[index] = `<span class="bold">${inputTextSplit[index]}</span>`;
            }
        }
    });

    return inputTextSplit.join(' ');
}

function setShowSideMenu(value: boolean) {
    showSideMenu.value = value;
}

</script>


<template>
    <nav class="bg-white p-3 sticky top-0 z-30 navbar-transition">
        <div class="flex flex-row sm:w-3/4 w-full gap-2 md:mx-auto justify-between">

            <div class="flex items-center lg:hidden">
                <button @click="setShowSideMenu(!showSideMenu)" aria-label="Menu">
                    <Icon name="mdi:hamburger-menu" size="38px" />
                </button>
            </div>

            <div class="flex flex-row gap-3 items-center justify-start">
                <NuxtLink :to="localizePath('/')"
                    class="inline-flex text-3xl bg-gray-100 p-1 rounded-lg hover:opacity-70 transition-opacity ease-linear">
                    <span class=" rounded-lg">E<span class="text-yellow-400">M</span></span>
                </NuxtLink>

                <Input placeholder="Search for articles" class="flex-1 w-full md:w-64" @click="openModal">
                <template #prefix>
                    <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none"
                        stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                    </svg>
                </template>
                </Input>
                <!--
                <Button color="yellow" class="align-middle">Subscribe!</Button>
                -->
            </div>

            <div class="flex flex-row items-center justify-end">

                <div class="flex flex-row dark:text-white gap-1">
                    
                    <NuxtLink :to="switchLocalePath('en')" :class="'p-2 hover:bg-gray-100 rounded-md ' + (locale == 'en' ? 'bg-gray-100': '' )">EN</NuxtLink>
                    <NuxtLink :to="switchLocalePath('es')" :class="'p-2 hover:bg-gray-100 rounded-md ' + (locale == 'es' ? 'bg-gray-100': '' )">ES</NuxtLink>
                    
                </div>

                <!--
                <div class="mr-2 ml-2 border-r-2 dark:border-r-white"></div>
                
                <button class="p-2 hover:bg-gray-100 rounded-md" @click="switchTheme()" id="theme-toggle" title="Toggles light &amp; dark">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" class="text-blue-950 inline text-2xl" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278z">
                        </path>
                    </svg>
                </button>
                -->
            </div>

        </div>

        <Modal v-if="showModal" @close="closeModal()">
            <template #header>
                <div class="flex items-center text-lg w-[90%] mx-auto">
                    <Input placeholder="Search for articles" class="w-full" @input="searchInputChanged">
                    <template #prefix>
                        <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none"
                            stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                        </svg>
                    </template>
                    </Input>
                </div>
            </template>
            <template #body>
                <div class="flex flex-col gap-3">
                    <template v-if="error">
                        An unexpected error ocurred trying to search articles :(
                    </template>
                    <template v-if="pending">
                        Loading ...
                    </template>
                    <template v-if="!pending && !error">
                        <template v-if="data && data.length">
                            <template v-for="article in data">
                                <NuxtLink :to="localizePath('/post/' + article.slug)" @click="closeModal();">
                                    <div
                                        class="select-none items-center rounded-md text-sm group py-3 text-scale-1100 bg-slate-50/50 hover:bg-slate-50/90 dark:bg-[#232323]/90 border border-[#ddd]/90 dark:border-[#282828]/90 backdrop-filter backdrop-blur-md px-5 transition-all outline-none aria-selected:border-[#ccc] dark:aria-selected:border-[#323232] aria-selected:bg-[#f1f3f5]/90 dark:aria-selected:bg-[#323232] aria-selected:shadow-sm aria-selected:scale-[100.3%] data-[disabled]:pointer-events-none data-[disabled]:opacity-50">
                                        <h3 class="text-xl font-semibold">{{ article.title }}</h3>
                                        <p class="text-xs">{{ article.description }}</p>
                                    </div>
                                </NuxtLink>
                            </template>
                        </template>
                        <template v-else>
                            No articles found with that search criteria
                        </template>
                    </template>
                </div>
            </template>
        </Modal>
    </nav>

    <aside id="default-sidebar" :class="`fixed top-0 left-0 z-40 w-64 h-screen transition-transform ${showSideMenu ? 'transform-none' : '-translate-x-full'}`" aria-label="Sidebar" aria-modal="true" aria-hidden="true" role="dialog">
        <div class="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
            <h4 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{{ t('home.categories') }}</h4>
            <ul class="space-y-2 font-medium">
                <li v-for="tag in tagsResponse.data.value?.data">
                    <NuxtLink @click="setShowSideMenu(false)" :to="localePath('/tag/' + tag.attributes.tagName)" class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                        <!--
                        <svg class="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 21">
                            <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z"/>
                            <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z"/>
                        </svg>
                        -->
                        <span class="ml-3"><span :style="{ 'color': tag.attributes.tagColor }">#</span>{{ tag.attributes.tagName }}</span>
                    </NuxtLink>
                </li>
            </ul>
        </div>
    </aside>

    <div @click="setShowSideMenu(false)" :class="`fixed h-screen w-screen bg-slate-800 bg-opacity-60 ${showSideMenu ? '' : 'hidden'}`"></div>

</template>
